import { useDescribeCatalogResourceCamelQuery } from '@vertice/slices/src/api/enhancedCatalogAPI';
import { FC, useCallback, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import { Loader } from '@verticeone/design-system';
import { TaskCustomForm } from './TaskCustomForm';
import { useDynamicFormPresenterConfig } from '@vertice/core/src/modules/forms/dynamicForms/DynamicFormPresenter/hooks/useDynamicFormPresenterConfig';
import { parseFormUrn } from '../../../workflowSchema/WorkflowEditor/forms/EditUserTaskForm/CustomForm';
import { isFormCatalogResource } from '../../../utils/catalogResourceUtils';
import { useFilePathPrefix } from './useFilePathPrefix';

const emptyFormSchema = { fields: [] };

export type TaskCustomFormSectionProps = {
  formUrn: string;
  onSubmit: (values: FieldValues) => Promise<void>;
};

export const TaskCustomFormSection: FC<TaskCustomFormSectionProps> = (props) => {
  const { formUrn, onSubmit } = props;

  const { accountId, formPath } = parseFormUrn(formUrn);
  const filePathPrefix = useFilePathPrefix();

  const { data: catalogResource, isLoading: isCatalogResourceLoading } = useDescribeCatalogResourceCamelQuery({
    accountId: accountId ?? 'GLOBAL',
    resourceId: encodeURIComponent(formPath),
  });

  const formSchema = useMemo(() => {
    if (isFormCatalogResource(catalogResource?.resource?.definition)) {
      return catalogResource?.resource?.definition?.form?.formUiModel?.layout ?? emptyFormSchema;
    }
    return null;
  }, [catalogResource]);

  const handleSubmit = useCallback(
    async (values: FieldValues) => {
      await onSubmit(values);
    },
    [onSubmit]
  );

  const formConfigQuery = useDynamicFormPresenterConfig('readWrite', filePathPrefix);

  if (isCatalogResourceLoading || formConfigQuery.isLoading) return <Loader />;

  return formSchema && <TaskCustomForm formSchema={formSchema} config={formConfigQuery.data} onSubmit={handleSubmit} />;
};
