export const FEATURES = {
  SSO: 'SSO',
  USAGE_ANALYTICS: 'USAGE_ANALYTICS',
  AWS_COST_INSIGHT: 'AWS_COST_INSIGHT',
  AWS_RIO: 'AWS_RIO',
  AWS_SPRIO: 'AWS_SPRIO',
  AWS_SPRIO_COMPUTE_SP: 'AWS_SPRIO_COMPUTE_SP',
  AWS_SPRIO_EC2_SP: 'AWS_SPRIO_EC2_SP',
  AWS_SPRIO_SAGEMAKER_SP: 'AWS_SPRIO_SAGEMAKER_SP',
  AWS_SPRIO_EC2_RI: 'AWS_SPRIO_EC2_RI',
  AWS_SPRIO_RDS_RI: 'AWS_SPRIO_RDS_RI',
  AWS_SPRIO_OPENSEARCH_RI: 'AWS_SPRIO_OPENSEARCH_RI',
  AWS_SPRIO_ELASTICACHE_RI: 'AWS_SPRIO_ELASTICACHE_RI',
  AWS_SPRIO_REDSHIFT_RI: 'AWS_SPRIO_REDSHIFT_RI',
  AWS_ANALYTICS: 'AWS_ANALYTICS',
  AWS_EDP_INSIGHT: 'AWS_EDP_INSIGHT',
  CM_ADVANCED_COST_MODELS: 'CM_ADVANCED_COST_MODELS',
  INTEGRATIONS: 'INTEGRATIONS',
  INTEGRATION_GOOGLE: 'INTEGRATION_GOOGLE',
  INTEGRATION_JIRA: 'INTEGRATION_JIRA',
  INTEGRATION_MERGE: 'INTEGRATION_MERGE',
  INTEGRATION_MS_DYNAMICS: 'INTEGRATION_MS_DYNAMICS',
  INTEGRATION_QUICKBOOKS: 'INTEGRATION_QUICKBOOKS',
  INTEGRATION_NETSUITE: 'INTEGRATION_NETSUITE',
  INTEGRATION_XERO: 'INTEGRATION_XERO',
  INTEGRATION_SLACK: 'INTEGRATION_SLACK',
  INTEGRATION_SLACK_V2: 'INTEGRATION_SLACK_V2',
  INTEGRATION_VISOTRUST: 'INTEGRATION_VISOTRUST',
  INSIGHTS: 'INSIGHTS',
  INSIGHT_ACCOUNTING: 'INSIGHT_ACCOUNTING',
  SAVINGS_DASHBOARD: 'SAVINGS_DASHBOARD',
  CONTRACTS_CUSTOMIZATION: 'CONTRACTS_CUSTOMIZATION',
  SAML_SCIM: 'SAML_SCIM',
  VENDOR_BENCHMARKING: 'VENDOR_BENCHMARKING',
  VENDOR_LEGAL: 'VENDOR_LEGAL',
  INTELLIGENT_WORKFLOWS: 'INTELLIGENT_WORKFLOWS',
  INTELLIGENT_WORKFLOWS_PAYWALL: 'INTELLIGENT_WORKFLOWS_PAYWALL',
  ZENDESK: 'ZENDESK',
} as const;
