import { FC, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Stack } from '@mui/material';
import { TextFieldCaption, Text } from '@verticeone/design-system';
import 'react-querybuilder/dist/query-builder.css';

import FormSelectField2 from '../../../../../forms/fields/FormSelectField2';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../constants';
import { useGatewayTypeOptions, Option } from './gatewayTypeOptions';
import { EditGatewayFormData } from './schema';
import { NodeId } from '../../types';

import { EdgeField } from './components/EdgeField';
import { Gateway, ProcessDefinition } from '../../../../definitionsTypes';
import { useVariablesAvailableInNode } from '../../hooks/useVariablesAvailableInNode';

type EditGatewayFormProps = {
  onDirty: VoidFunction;
  onEdgeSelected: (edgeId: string | undefined) => void;
  selectedEdgeId: string | undefined;
  gatewayId: NodeId;
  gatewayType: Gateway['gatewayType'];
  workflowServiceRef: string;
  processDefinition: ProcessDefinition;
};

const GATEWAY_TYPE_SELECT_ID = 'gateway-type-select';
/*
 * This is a temporary solution to hide the gateway type select field.
 * It is likely that the Product team will want to enable this field in the near future.
 */
const IS_GATEWAY_TYPE_SELECT_ENABLED = false;

const GATEWAY_COMBINATORS_KEY_MAP = {
  Exclusive: 'EXPRESSION_BUILDER.COMBINATORS.OR',
  Fork: 'EXPRESSION_BUILDER.COMBINATORS.AND',
  Join: null,
} as const;

export const EditGatewayForm: FC<EditGatewayFormProps> = ({
  onDirty,
  processDefinition,
  workflowServiceRef,
  gatewayId,
  gatewayType,
  onEdgeSelected,
  selectedEdgeId,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR' });

  const { requestVariables, udfVariables } = useVariablesAvailableInNode({
    nodeId: gatewayId,
    processDefinition,
    workflowServiceRef,
  });

  const { formState } = useFormContext<EditGatewayFormData>();

  const { fields } = useFieldArray<EditGatewayFormData, 'edges'>({
    name: 'edges',
  });

  useEffect(() => {
    if (formState.isDirty) {
      onDirty();
    }
  }, [formState.isDirty, onDirty]);

  const gatewayTypeOptions = useGatewayTypeOptions();

  const combinatorKey = GATEWAY_COMBINATORS_KEY_MAP[gatewayType];

  const isJoinGateway = gatewayType === 'Join';

  return (
    <Stack gap={5} direction="column" width="100%">
      {IS_GATEWAY_TYPE_SELECT_ENABLED && (
        <Stack gap={1}>
          <TextFieldCaption label={t('EDIT_GATEWAY.TYPE')} size="XS" htmlFor={GATEWAY_TYPE_SELECT_ID} />
          <FormSelectField2<EditGatewayFormData, Option, false>
            id={GATEWAY_TYPE_SELECT_ID}
            unboxValue={(selectValue) => selectValue?.value}
            boxValue={(value) => gatewayTypeOptions.find((option) => option.value === value)}
            name="gatewayType"
            variant="outlined"
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            options={gatewayTypeOptions}
            isClearable={false}
            size="S"
            isDisabled
          />
        </Stack>
      )}
      <Stack direction="column" gap={2}>
        {fields.map((field, index) => (
          <Fragment key={field.id}>
            <EdgeField
              requestVariables={requestVariables}
              udfVariables={udfVariables}
              key={field.id}
              edge={field}
              index={index}
              gatewayType={gatewayType}
              onSelectionChanged={onEdgeSelected}
              selectedEdgeId={selectedEdgeId}
            />
            {!isJoinGateway && combinatorKey && index !== fields.length - 1 && (
              <Text variant="label" color="text3" size="XXS">
                {t(combinatorKey)}
              </Text>
            )}
          </Fragment>
        ))}
      </Stack>
    </Stack>
  );
};
