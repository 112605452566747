import { Edge, Node } from '@xyflow/react';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSimpleDialogContext } from '@verticeone/design-system';
import { AppTypeContext } from '../../../../contexts/AppTypeContext';
import { FEATURES } from '../../../features/constants';
import { useFeatures } from '../../../features/useFeatures';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../constants';
import { isGatewayDefinition } from '../../definitions/processDefinition';
import { GatewayDefinition, ProcessDefinition, TaskDefinition } from '../../definitionsTypes';
import { isNodeGatewayNode, isNodeLabelNode, isNodeTaskNode } from '../WorkflowRenderer/types';
import { useWorkflowRendererState } from '../WorkflowRenderer/useWorkflowRendererState';
import { checkServiceTaskIsEditable } from './EditNodeDrawers/EditServiceTaskDrawer/utils';

type UseWorkflowEditingProps = {
  processDefinition?: ProcessDefinition;
};

export const useWorkflowEditing = ({ processDefinition }: UseWorkflowEditingProps) => {
  const { t } = useTranslation();
  const { getFeature } = useFeatures();
  const { isIAT } = useContext(AppTypeContext);

  const { getConfirmation } = useSimpleDialogContext();
  const [hasChange, setHasChange] = useState(false);
  const isAdvancedWorkflowDefinitionEditModeEnabled =
    getFeature(FEATURES.INTELLIGENT_WORKFLOWS)?.properties?.gatewayVariables || isIAT;
  const [selectedNodeDefinition, setSelectedNodeDefinition] = useState<GatewayDefinition | TaskDefinition | undefined>(
    undefined
  );
  const [selectedEdgeId, setSelectedEdgeId] = useState<string>();

  const onSelectionChanged = useCallback(
    (node?: Node, edge?: Edge) => {
      if (!processDefinition) {
        return;
      }
      const { tasks = [], gateways = [], flow } = processDefinition.process;

      if (isNodeTaskNode(node)) {
        const taskId = node.data.id;
        const definitionTaskNode = tasks.find(({ task }) => task.id === taskId);

        if (
          definitionTaskNode?.task.taskType === 'User' ||
          (definitionTaskNode?.task.taskType === 'Service' && checkServiceTaskIsEditable(definitionTaskNode.task))
        ) {
          setSelectedNodeDefinition(definitionTaskNode);
        } else {
          setSelectedNodeDefinition(undefined);
        }
        setSelectedEdgeId(undefined);
      }

      if (isNodeGatewayNode(node) || isNodeLabelNode(node)) {
        if (!isAdvancedWorkflowDefinitionEditModeEnabled) {
          return;
        }
        const gatewayId = isNodeGatewayNode(node) ? node.data.id : node.data.from;
        const definitionGatewayNode = gateways.find(({ gateway }) => gateway.id === gatewayId);

        if (definitionGatewayNode) {
          setSelectedNodeDefinition(definitionGatewayNode);
          if (isNodeLabelNode(node)) {
            setSelectedEdgeId(node.data.originalEdgeId);
          } else {
            setSelectedEdgeId(undefined);
          }
        } else {
          setSelectedNodeDefinition(undefined);
          setSelectedEdgeId(undefined);
        }
      }

      if (edge) {
        const definitionEdge = flow?.flow.edges?.find(
          (e) => e.edge.id === edge?.data?.id || e.edge.id === edge?.data?.originalEdgeId
        );

        const definitionGatewayNode = gateways.find(({ gateway }) => gateway.id === definitionEdge?.edge.from);
        if (definitionGatewayNode) {
          setSelectedNodeDefinition(definitionGatewayNode);
          setSelectedEdgeId(definitionEdge?.edge.id);
        } else {
          setSelectedNodeDefinition(undefined);
          setSelectedEdgeId(undefined);
        }
      }

      setHasChange(false);
    },
    [processDefinition, isAdvancedWorkflowDefinitionEditModeEnabled]
  );

  const activeGatewayLeavingEdges = useMemo(
    () =>
      isGatewayDefinition(selectedNodeDefinition) && processDefinition
        ? (processDefinition.process.flow?.flow.edges ?? []).filter(
            ({ edge }) => edge.from === selectedNodeDefinition.gateway.id
          )
        : [],
    [selectedNodeDefinition, processDefinition]
  );

  const checkChangeSelectionAllowed = useCallback(async () => {
    if (!selectedNodeDefinition || !hasChange) {
      return true;
    }

    await getConfirmation({
      title: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.DISCARD_TASK_CHANGES_DIALOG.HEADER'),
      okButton: {
        color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
      },
      cancelButton: {
        hidden: true,
      },
    });

    return false;
  }, [selectedNodeDefinition, getConfirmation, hasChange, t]);

  const { clearRendererSelection } = useWorkflowRendererState({
    onSelectionChanged,
    checkChangeSelectionAllowed,
  });

  const clearSelection = useCallback(() => {
    setSelectedNodeDefinition(undefined);
    setSelectedEdgeId(undefined);
    clearRendererSelection();
  }, [clearRendererSelection]);

  const onNodeChanged = useCallback(() => setHasChange(true), []);

  return useMemo(
    () => ({
      selectNode: onSelectionChanged,
      selectedNodeDefinition,
      selectEdge: setSelectedEdgeId,
      selectedEdgeId,
      clearSelection,
      activeGatewayLeavingEdges,
      isAdvancedWorkflowDefinitionEditModeEnabled,
      onNodeChanged,
    }),
    [
      activeGatewayLeavingEdges,
      selectedNodeDefinition,
      isAdvancedWorkflowDefinitionEditModeEnabled,
      onNodeChanged,
      onSelectionChanged,
      selectedEdgeId,
      clearSelection,
    ]
  );
};
