import { FC } from 'react';

import { MoneyFieldType } from './types';
import { Stack } from '@mui/material';
import { FormMoneyField, FormMoneyFieldType } from '../../../fields/FormMoneyField';
import FormEntry from '../../../fields/FormEntry';
import { FieldValues } from 'react-hook-form';

import { DynamicFormPresenterConfig } from '../../types';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../intelligentWorkflows/constants';

type MoneyFieldPresenterProps = {
  field: MoneyFieldType;
  config: DynamicFormPresenterConfig;
};

export const MoneyFieldPresenter: FC<MoneyFieldPresenterProps> = (props) => {
  const { field, config } = props;
  const { label, required, description } = field.metadata;

  return (
    <Stack py={3}>
      <FormEntry<FieldValues, FormMoneyFieldType<FieldValues>>
        name={field.name}
        component={FormMoneyField}
        label={label}
        description={description}
        required={required}
        disabled={config.mode === 'readOnly'}
        componentProps={{
          allowsFloat: true,
          color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
        }}
      />
    </Stack>
  );
};
