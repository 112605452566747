import React, { FC, useCallback, useEffect } from 'react';
import { Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { get } from 'lodash';
import { Alert, AlertButton } from '@verticeone/design-system';
import { useGoodFormUtils } from '@vertice/core/src/modules/forms/utils/goodFormUtils';
import { FormSchemaProvider } from '@vertice/core/src/modules/forms/schema/FormSchemaContext';
import { useTaskContext } from '../TaskContext';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useDraftUserTaskMutation } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';
import { enqueueSnackbar } from 'notistack';
import { useDebouncedCallback } from 'use-debounce';
import { usePredefinedFormDef } from './usePredefinedFormDef';
import { TaskFormContextProvider } from './predefinedForms/shared/TaskFormContext';
import { Divider } from '@verticeone/design-system';
import { useScrollBoundaries } from '../../../../../hooks/useScrollBoundaries';
import { PagesSidebar } from './pages/PagesSidebar';
import { PagesProvider } from './pages/PagesContext';
import { useTaskVisitorState } from './useTaskVisitorState';
import { TaskPredefinedFormButtons } from './TaskPredefinedFormButtons';

type TaskPredefinedFormProps = {
  onSubmit: (resultBody: object) => Promise<void>;
  onCancel: () => void;
  formConfig: { [p: string]: any };
};

const TaskPredefinedForm: FC<TaskPredefinedFormProps> = ({ onSubmit, onCancel, formConfig }) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const formDef = usePredefinedFormDef(formConfig);

  const taskContextData = useTaskContext();
  const { accountId } = useAccountContext();

  const { scrollBoundaries, onScrollHandler } = useScrollBoundaries();

  const { getVisitedPagesDataForDraft, visitedPages, onPageVisited, currentPageId } = useTaskVisitorState(
    taskContextData.taskOverview?.task?.draft
  );

  // We need to pass an empty error map to zodResolver, because we don't want to show any error messages
  const schemaOptions = { errorMap: () => ({ message: '' }) };

  const getTaskDataValue = useCallback(
    (path: string, defaultValue: any) =>
      get(taskContextData.taskOverview?.task?.draft, path) ??
      get(taskContextData.taskOverview?.task?.input, path) ??
      defaultValue,
    [taskContextData.taskOverview?.task?.draft, taskContextData.taskOverview?.task?.input]
  );

  const formMethods = useForm({
    mode: 'all', // Validate on change, blur, submit
    resolver: formDef?.schema ? zodResolver(formDef?.schema, schemaOptions) : undefined,
    defaultValues: formDef?.getDefaultValues?.(getTaskDataValue, taskContextData),
  });
  const { handleSubmit } = useGoodFormUtils(formMethods);

  const submitTransformed = async (formData: object) => {
    await onSubmit(formDef?.transformOutput ? formDef.transformOutput(formData) : formData);
  };

  const { watch, getValues } = formMethods;
  const [updateDraftUserTask] = useDraftUserTaskMutation();

  const saveDraftDebounced = useDebouncedCallback(
    (formValues) => {
      if (formMethods.formState.isSubmitting || formMethods.formState.isSubmitted) return;
      const visitedPagesData = getVisitedPagesDataForDraft();
      updateDraftUserTask({
        accountId: accountId,
        taskId: taskContextData.task.id,
        body: {
          ...formValues,
          ...visitedPagesData,
        },
      }).catch((e) => {
        enqueueSnackbar(t('PREFERENCES.USER.SNACKBAR.ERROR'), {
          variant: 'error',
        });
      });
    },
    500,
    { maxWait: 1000 }
  );

  useEffect(() => {
    if (!formDef?.draftEnabled) return;
    const { unsubscribe } = watch((formValues) => {
      saveDraftDebounced(formValues);
    });
    return () => unsubscribe();
  }, [watch, formDef?.draftEnabled, saveDraftDebounced]);

  const handleNavigation = useCallback(
    (pageId: string) => {
      const formValues = formDef?.draftEnabled ? getValues() : {};
      saveDraftDebounced(formValues);
      onPageVisited(pageId);
    },
    [formDef?.draftEnabled, getValues, onPageVisited, saveDraftDebounced]
  );

  return (
    <FormSchemaProvider value={formDef?.schema ?? null}>
      <TaskFormContextProvider>
        <FormProvider {...formMethods}>
          <PagesProvider
            pages={formDef?.pages}
            schema={formDef?.schema}
            onPageVisited={handleNavigation}
            defaultPageId={currentPageId}
          >
            <form
              onSubmit={handleSubmit(submitTransformed)}
              style={{ display: 'flex', minHeight: 0, height: '100%', minWidth: 0, width: '100%' }}
            >
              <PagesSidebar visitedPages={visitedPages} />
              <Stack minHeight={0} width="100%" height="100%">
                <Stack padding={6} overflow="auto" minHeight={0} height="100%" onScroll={onScrollHandler}>
                  {formDef ? (
                    <formDef.component />
                  ) : (
                    <Alert
                      size="S"
                      variant="ghost"
                      color="error"
                      title={t('INTELLIGENT_WORKFLOWS.TASK_MODAL.UNKNOWN_FORM')}
                      subtitle={formConfig.formUrn}
                    >
                      <AlertButton onClick={onCancel}>{t('DIALOG.BUTTONS.CLOSE')}</AlertButton>
                    </Alert>
                  )}
                </Stack>
                <Stack
                  sx={{
                    boxShadow: scrollBoundaries.bottom
                      ? `${palette.global.getShadow({
                          color: 'core',
                          type: 'soft',
                          depth: '3z',
                          distance: '80',
                        })}, ${palette.global.getShadow({ color: 'core', type: 'soft', depth: '3z', distance: '70' })}`
                      : 'none',
                    transition: 'box-shadow 0.3s ease-in-out',
                  }}
                >
                  <Divider />
                  {Boolean(formDef) && <TaskPredefinedFormButtons />}
                </Stack>
              </Stack>
            </form>
          </PagesProvider>
        </FormProvider>
      </TaskFormContextProvider>
    </FormSchemaProvider>
  );
};

export default TaskPredefinedForm;
