import { useCallback, useMemo, useState } from 'react';
import { BuilderPlugin, BuilderPluginComponent } from '../../../../../../../forms/dynamicForms';
import { z } from 'zod';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { EditUserTaskFormData } from '../../schema';
import { VARIABLES_FIELD_NAME } from '../constants';
import { useCustomFormEditContext } from '../CustomFormEditContext';
import { VariableChip } from '../../../../VariableSelector/VariableChip';
import { VariablesDialog } from '../../../../VariableSelector/VariablesDialog';
import { useWorkflowVariablesGroups } from '../../../EditGatewayForm/useWorkflowVariablesGroups';
import { Variable, VariableId } from '../../../../types';
import { Stack } from '@mui/material';
import { TextFieldCaption } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { fieldDefsByType } from '../../../../../../../forms/dynamicForms/fields/fields';
import { useServiceCatalogResources } from '../../../../../WorkflowViewer/useServiceCatalogResources';
import { getVariableTypeFromJsonSchema } from '../../../../../utils';
import { RESERVED_VARIABLES } from '../../../../EditNodeDrawers/EditUserTaskDrawer/useTaskFormSubmit';

type VariablePickerValues = {
  variableId: string | null;
  usedVariableIds: VariableId[];
};

export type VariablePickerBuilderPlugin = BuilderPlugin<VariablePickerValues>;

const VariablePickerComponent: BuilderPluginComponent = (props) => {
  const { name, fieldType } = props;
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { plugins, ...metadata } = useWatch();
  const { variables, nodeId, createVariable } = useCustomFormEditContext();
  const normalizedVariables = useMemo<Variable[]>(() => {
    return variables.filter((x) => !RESERVED_VARIABLES.includes(x.id));
  }, [variables]);

  const { xTypeServiceCatalogResources: xTypeResources } = useServiceCatalogResources();

  const { field: usedVariableIdsField } = useController({ name: `${name}.usedVariableIds` });
  const { field: selectedVariableField } = useController({ name: `${name}.variableId` });

  const variablesGroups = useWorkflowVariablesGroups({ allRequestVariables: normalizedVariables, udfVariables: [] });

  const selectedVariable = useMemo<Variable | null>(() => {
    if (!selectedVariableField.value) return null;
    return normalizedVariables.find((v) => v.id === selectedVariableField.value) ?? null;
  }, [selectedVariableField, normalizedVariables]);

  const handleSelectVariable = (variable: Variable | null) => {
    selectedVariableField.onChange(variable ? variable.id : null);
    setIsDialogOpen(false);
  };

  const handleCreateVariable = (variable: Variable) => {
    createVariable(variable);
    handleSelectVariable(variable);
  };

  const addVariableDefaults = useMemo<Pick<Variable, 'type' | 'origin'>>(() => {
    const jsonSchema = fieldDefsByType[fieldType].getJsonSchemaFromMetadata(metadata);
    const variableType = getVariableTypeFromJsonSchema({ jsonSchema, xTypeResources });

    return { type: variableType, origin: { id: nodeId, kind: 'vertice-task-output-mapping' } };
  }, [nodeId, metadata, fieldType, xTypeResources]);

  return (
    <>
      <Stack gap={0.5} alignItems="start" sx={{ zIndex: 1 }}>
        <TextFieldCaption label={t('DYNAMIC_FORM_BUILDER.VARIABLE_PLUGIN.LABEL')} size="XS" />
        <VariableChip
          label={selectedVariable?.label}
          typeLabel={selectedVariable?.type.labels?.[0]}
          onClick={() => setIsDialogOpen(true)}
          onDeselectIconClick={() => handleSelectVariable(null)}
          withIcon
        />
      </Stack>
      <VariablesDialog
        selectedVariableId={selectedVariable?.id}
        variablesGroups={variablesGroups}
        disabledVariables={usedVariableIdsField.value}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onVariableClick={handleSelectVariable}
        onAddLocalVariable={handleCreateVariable}
        addVariableDefaults={addVariableDefaults}
        withBackdrop={true}
      />
    </>
  );
};

export const useVariablePickerBuilderPluginDefinition = () => {
  const { getValues, setValue } = useFormContext<EditUserTaskFormData>();

  const create = useCallback(
    (fieldPath?: string): BuilderPlugin<VariablePickerValues> => {
      const variables = getValues(VARIABLES_FIELD_NAME) ?? {};
      const usedVariables = Object.entries(variables)
        .filter(([field, variableId]) => field !== fieldPath && variableId !== null)
        .map(([, variableId]) => variableId as string);

      return {
        defaultValues: {
          variableId: variables[fieldPath ?? ''] ?? null,
          usedVariableIds: usedVariables,
        },
        schema: z.object({
          variableId: z.string().nullable(),
          usedVariableIds: z.array(z.string()),
        }),
        Component: VariablePickerComponent,
      };
    },
    [getValues]
  );

  const handleSubmit = useCallback(
    (fieldPath: string, values: VariablePickerValues) => {
      setValue(`${VARIABLES_FIELD_NAME}.${fieldPath}`, values.variableId);
    },
    [setValue]
  );

  return { create, handleSubmit };
};
