import React, { useCallback, useEffect, useMemo } from 'react';
import { Definitions, TaskDefinition, GatewayDefinition, FlowEdgeDefinition } from '../../definitionsTypes';
import {
  isGatewayDefinition,
  isTaskDefinition,
  updateEdgesInProcessDefinition,
  updateNodeInProcessDefinition,
} from '../../definitions/processDefinition';
import { EditUserTaskDrawer } from './EditNodeDrawers/EditUserTaskDrawer/EditUserTaskDrawer';
import { EditGatewayDrawer } from './EditNodeDrawers/EditGatewayDrawer/EditGatewayDrawer';
import { isProcessDefinition } from '../../pocWorkflowSchema';
import { EditServiceTaskDrawer } from './EditNodeDrawers/EditServiceTaskDrawer/EditServiceTaskDrawer';
import { useWorkflowValidationContext, ValidationDrawer } from './Validation';
import { WorkflowRendererContextProvider } from '../WorkflowRenderer/WorkflowRendererContext';
import { processDefinitionToModel } from '../model/processDefinitionToModel';
import { useServicesThumbnails } from '../WorkflowViewer/useServicesThumbnails';
import { useLoggedUser } from '@verticeone/auth/src';
import { useAccountContext } from '../../../../contexts/AccountContext';
import { useRelevantUsersById } from '../../../../hooks/useRelevantUsersById';
import { WorkflowRenderer } from '../WorkflowRenderer/WorkflowRenderer';
import { useWorkflowEditing } from './useWorkflowEditing';
import { WorkflowState } from '../model/types';
import { withWorkflowRenderer } from '../WorkflowRenderer/useWorkflowRendererState';

type WorkflowEditorProps = {
  definitions?: Definitions;
  serviceRef?: string;
  setDefinitions: (definitions: Definitions) => void;
};

const BaseWorkflowEditor = ({ definitions, setDefinitions, serviceRef }: WorkflowEditorProps) => {
  const { accountId } = useAccountContext();
  const { userId: loggedUserId } = useLoggedUser();

  const { usersById } = useRelevantUsersById({ includeLoggedVerticeUser: true });
  const servicesThumbnails = useServicesThumbnails();

  const processDefinition = definitions?.definitions.find(isProcessDefinition);

  const {
    selectNode,
    selectedNodeDefinition,
    selectEdge,
    selectedEdgeId,
    clearSelection,
    onNodeChanged,
    isAdvancedWorkflowDefinitionEditModeEnabled,
    activeGatewayLeavingEdges,
  } = useWorkflowEditing({
    processDefinition,
  });

  const editorWorkflowState: WorkflowState = useMemo(
    () => ({
      tasks: {},
      gateways:
        selectedNodeDefinition?.kind === 'ProcessEngine:Gateway'
          ? { [selectedNodeDefinition.gateway.id]: { selected: true } }
          : {},
      edges: selectedEdgeId ? { [selectedEdgeId]: { selected: true } } : {},
    }),
    [selectedNodeDefinition, selectedEdgeId]
  );

  const model = useMemo(
    () =>
      processDefinition
        ? processDefinitionToModel({ processDefinition, workflowState: editorWorkflowState, servicesThumbnails })
        : undefined,
    [processDefinition, editorWorkflowState, servicesThumbnails]
  );

  const { isValidationDrawerOpen, setIsValidationDrawerOpen } = useWorkflowValidationContext();
  // close validation drawer on selected node change
  useEffect(() => {
    setIsValidationDrawerOpen(false);
  }, [setIsValidationDrawerOpen, selectedNodeDefinition]);

  const editorConfig = {
    allowContractOwnerAssignment:
      !!serviceRef?.includes('service/saas/renewal') ||
      !!serviceRef?.includes('service/saas/securityReview') ||
      !!serviceRef?.includes('service/saas/internal-negotiation') ||
      !!serviceRef?.includes('service/saas/triage'),
  };

  const handleSaveNodeDrawer = useCallback(
    (newNodeDefinition: TaskDefinition | GatewayDefinition, newEdgesDefinitions?: FlowEdgeDefinition[]) => {
      if (!definitions) {
        return;
      }

      const newDefinitions = updateNodeInProcessDefinition(definitions, newNodeDefinition);
      if (newEdgesDefinitions) {
        const newDefinitionsWithUpdatedEdges = updateEdgesInProcessDefinition(newDefinitions, newEdgesDefinitions);
        setDefinitions(newDefinitionsWithUpdatedEdges);
      } else {
        setDefinitions(newDefinitions);
      }

      clearSelection();
    },
    [definitions, clearSelection, setDefinitions]
  );

  const commonDrawerProps = useMemo(
    () => ({
      onClose: clearSelection,
      onDirty: () => onNodeChanged(),
      processDefinition,
      workflowServiceRef: serviceRef,
    }),
    [onNodeChanged, processDefinition, serviceRef, clearSelection]
  );

  if (!model) {
    return null;
  }

  return (
    <WorkflowRendererContextProvider
      value={{
        model,
        usersById,
        isEditor: true,
        loggedUserId,
        accountId,
        allowVerticeServiceNavigation: false,
        workflowDefinitions: definitions,
        drawerTaskId: undefined,
      }}
    >
      <WorkflowRenderer />
      <EditUserTaskDrawer
        isOpen={isTaskDefinition(selectedNodeDefinition) && selectedNodeDefinition.task.taskType === 'User'}
        task={isTaskDefinition(selectedNodeDefinition) ? selectedNodeDefinition : undefined}
        onSave={handleSaveNodeDrawer}
        editorConfig={editorConfig}
        isAdvancedWorkflowDefinitionEditModeEnabled={isAdvancedWorkflowDefinitionEditModeEnabled}
        {...commonDrawerProps}
      />
      <EditServiceTaskDrawer
        isOpen={isTaskDefinition(selectedNodeDefinition) && selectedNodeDefinition.task.taskType === 'Service'}
        task={isTaskDefinition(selectedNodeDefinition) ? selectedNodeDefinition : undefined}
        onSave={handleSaveNodeDrawer}
        editorConfig={editorConfig}
        {...commonDrawerProps}
      />
      <EditGatewayDrawer
        isOpen={isGatewayDefinition(selectedNodeDefinition)}
        gatewayDefinition={isGatewayDefinition(selectedNodeDefinition) ? selectedNodeDefinition : undefined}
        onSave={handleSaveNodeDrawer}
        gatewayLeavingEdges={activeGatewayLeavingEdges}
        onEdgeSelected={selectEdge}
        selectedEdgeId={selectedEdgeId}
        {...commonDrawerProps}
      />
      <ValidationDrawer isOpen={isValidationDrawerOpen} handleResolve={selectNode} />
    </WorkflowRendererContextProvider>
  );
};

// eslint-disable-next-line testing-library/render-result-naming-convention
export const WorkflowEditor = withWorkflowRenderer(BaseWorkflowEditor);
