import { FC, useCallback, useMemo, useState } from 'react';
import { QueryBuilder as ReactQueryBuilder, formatQuery, defaultRuleProcessorJsonLogic } from 'react-querybuilder';
import { parseJsonLogic } from 'react-querybuilder/parseJsonLogic';
import type { RuleGroupType, RuleProcessor } from 'react-querybuilder';

import type { Variable } from '../types';
import type { FlowEdgeCondition } from '../../../definitionsTypes';
import {
  OperatorSelector,
  CombinatorSelector,
  FieldSelector,
  AddRuleAction,
  AddGroupAction,
  RemoveRuleAction,
  RuleGroup,
  Rule,
  RemoveGroupAction,
  ValueEditor,
} from './components';
import { isQueryValid, ruleValidator } from './utils';
import { useFieldsFromVariables } from './useFieldsFromVariables';
import { DEFAULT_RULE, DEFAULT_QUERY, JSON_LOGIC_OPERATIONS } from './constants';

type QueryBuilderCustomContext = {
  requestVariables: Variable[];
  udfVariables: Variable[];
  isConditionRemovalEnabled: boolean;
};

type QueryBuilderProps = {
  initialCondition?: string;
  onConditionChange: (condition: string, conditionType?: FlowEdgeCondition['conditionType']) => void;
  isConditionRemovalEnabled?: boolean;
  requestVariables: Variable[];
  udfVariables: Variable[];
};

export const QueryBuilder: FC<QueryBuilderProps> = ({
  onConditionChange,
  initialCondition,
  udfVariables,
  requestVariables,
  isConditionRemovalEnabled = false,
}) => {
  const [query, setQuery] = useState<RuleGroupType>(
    initialCondition
      ? parseJsonLogic(initialCondition, {
          jsonLogicOperations: JSON_LOGIC_OPERATIONS,
        })
      : DEFAULT_QUERY
  );

  const customContext: QueryBuilderCustomContext = useMemo(
    () => ({
      isConditionRemovalEnabled,
      udfVariables,
      requestVariables,
    }),
    [udfVariables, isConditionRemovalEnabled, requestVariables]
  );

  const fields = useFieldsFromVariables([...requestVariables, ...udfVariables]);

  const jsonLogicRuleProcessor: RuleProcessor = useCallback((rule, options, meta) => {
    if (typeof rule.value === 'string' && rule.value.startsWith('udf(')) {
      return defaultRuleProcessorJsonLogic({ ...rule, valueSource: 'field' }, options, meta);
    }

    // Narrow down the rule to boolean operators, parser ensures we will only have === or !==
    // And our mappings ensure these are only used for boolean values
    if (rule.operator === '===' || rule.operator === '!==') {
      return { [rule.operator]: [{ var: rule.field }, rule.value] };
    }

    return defaultRuleProcessorJsonLogic(rule, options, meta);
  }, []);

  const handleQueryChange = (newQuery: RuleGroupType) => {
    setQuery(newQuery);

    if (!isQueryValid(newQuery)) {
      onConditionChange('');
      return;
    }

    onConditionChange(
      JSON.stringify(
        formatQuery(newQuery, {
          format: 'jsonlogic',
          ruleProcessor: jsonLogicRuleProcessor,
        })
      ),
      'JsonLogic'
    );
  };

  return (
    <ReactQueryBuilder
      controlElements={{
        removeRuleAction: RemoveRuleAction,
        combinatorSelector: CombinatorSelector,
        fieldSelector: FieldSelector,
        rule: Rule,
        addRuleAction: AddRuleAction,
        addGroupAction: AddGroupAction,
        valueEditor: ValueEditor,
        operatorSelector: OperatorSelector,
        ruleGroup: RuleGroup,
        removeGroupAction: RemoveGroupAction,
      }}
      query={query}
      onQueryChange={handleQueryChange}
      showCombinatorsBetweenRules
      context={customContext}
      fields={fields}
      getDefaultOperator={() => ''}
      getDefaultValue={() => ''}
      onAddRule={() => DEFAULT_RULE}
      accessibleDescriptionGenerator={() => ''}
      validator={ruleValidator}
    />
  );
};
