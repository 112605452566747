import { FC, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useBlocker, useParams } from 'react-router-dom';

import { Placeholder, Divider, ChipButton } from '@verticeone/design-system';

import { useRouteNavigate, useRoutes } from '@verticeone/router/src';

import { useConfirmPageExit } from '@vertice/core/src/hooks/useConfirmPageExit';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { Definitions } from '@vertice/core/src/modules/intelligentWorkflows/definitionsTypes';
import { IntelligentWorkflowsLayout } from '@vertice/core/src/modules/intelligentWorkflows/components/IntelligentWorkflowsLayout';
import {
  WorkflowValidationProvider,
  WorkflowEditor,
  Validator,
} from '@vertice/core/src/modules/intelligentWorkflows/workflowSchema';

import { useGetWorkflowVersionQuery } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useListServiceCatalogQuery } from '@vertice/slices/src/openapi/codegen/servicesAPI';

import { useMainLayout } from '../../../../layouts/Main/LayoutWrapper';
import { useWorkflowVersions } from '../WorkflowDetailPage/useWorkflowVersions';
import { DiscardChangesDialog } from './components/DiscardChangesDialog';
import { Actions } from './Actions';

export const WorkflowEditorPage: FC = () => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const routes = useRoutes();
  const { generatePathForRoute } = useRouteNavigate();
  const { setOpenOverride } = useMainLayout();

  useEffect(() => {
    if (!setOpenOverride) {
      return;
    }

    setOpenOverride(false);
    return () => setOpenOverride(undefined);
  }, [setOpenOverride]);

  const params = useParams() as { workflowId: string; versionId: string; serviceId: string };

  const {
    data: workflowVersionData,
    isLoading: isWorkflowVersionLoading,
    refetch,
  } = useGetWorkflowVersionQuery({
    accountId,
    workflowId: params.workflowId,
    workflowVersion: params.versionId,
  });

  const { activeVersion } = useWorkflowVersions(params.workflowId);

  const workflowDefinition = workflowVersionData?.workflowDefinition as Definitions;
  const [workingDefinitions, setWorkingDefinitions] = useState<Definitions | undefined>(workflowDefinition);
  const [hasChanges, setHasChanges] = useState(false);
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    const isChangingPage = nextLocation.pathname !== currentLocation.pathname;
    const isExitAction = Boolean(new URLSearchParams(currentLocation.search).get('exit'));

    return hasChanges && isChangingPage && !isExitAction;
  });

  const { data: servicesData } = useListServiceCatalogQuery({
    accountId,
  });
  const service = servicesData?.items?.find((s) => s.urn === activeVersion?.serviceRef);

  useEffect(() => {
    if (!isEqual(workingDefinitions, workflowDefinition)) setHasChanges(true);
    else setHasChanges(false);
  }, [workingDefinitions, workflowDefinition]);

  useEffect(() => {
    if (workflowDefinition) {
      setWorkingDefinitions(workflowDefinition);
    }
  }, [workflowDefinition]);

  useConfirmPageExit(hasChanges);

  if (isWorkflowVersionLoading) {
    return <Placeholder height="100%" />;
  }

  const backPath = generatePathForRoute(routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.SERVICE.DETAIL, {
    workflowId: params.workflowId,
    serviceId: params.serviceId,
  });

  const backPathWithTabParam = `${backPath}?${new URLSearchParams({ tab: 'drafts' }).toString()}`;

  return (
    <Stack height={'100%'} minWidth={'100%'}>
      <WorkflowValidationProvider>
        <IntelligentWorkflowsLayout
          parentPageAccountId={accountId}
          parentPageName={activeVersion?.workflowName}
          parentPagePath={backPathWithTabParam}
          grandparentPageName={service?.name}
          grandparentPagePath={generatePathForRoute(routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.SERVICE, {
            serviceId: params.serviceId,
          })}
          currentPageName={workflowVersionData?.versionName}
          currentPageAdornments={{
            end: (
              <ChipButton variant="solid" color="transparent" size="S" style={{ cursor: 'default', flexShrink: 0 }}>
                {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.DRAFT')}
              </ChipButton>
            ),
          }}
          actions={
            <Actions
              definitions={workingDefinitions}
              workflowVersionData={workflowVersionData}
              workflowId={params.workflowId}
              backPath={backPathWithTabParam}
              refetch={refetch}
              hasChanges={hasChanges}
            />
          }
          padding={0}
          gap={0}
        >
          <Divider />
          <Stack flexGrow={1}>
            {workflowDefinition ? (
              <WorkflowEditor
                definitions={workingDefinitions}
                setDefinitions={setWorkingDefinitions}
                serviceRef={workflowVersionData?.serviceRef}
              />
            ) : (
              <Placeholder height="100%" />
            )}
          </Stack>
          <DiscardChangesDialog
            open={blocker.state === 'blocked'}
            onClose={() => blocker.reset?.()}
            onDiscard={() => blocker.proceed?.()}
          />
        </IntelligentWorkflowsLayout>
        <Validator definitions={workingDefinitions} />
      </WorkflowValidationProvider>
    </Stack>
  );
};
