import { createPortableRoutes } from '@verticeone/router/src/routes/utils';

export const preferencesRoutes = createPortableRoutes({
  PREFERENCES: {
    path: '/preferences',
    children: {
      COMPANY: {
        path: 'company',
      },
      DIRECTORY: {
        path: 'directory',
      },
      WORKFLOWS: {
        path: 'workflows',
      },
      CUSTOMIZATION: {
        path: 'customization',
      },
      USER: {
        path: 'user',
        children: {
          SLACK_WIZARD: {
            path: 'slack',
          },
        },
      },
      INTEGRATIONS: {
        path: 'integrations',
        children: {
          ACCOUNTING: {
            path: 'accounting',
            children: {
              PROVIDER: {
                path: ':providerId',
              },
            },
          },
          USAGE: {
            path: 'usage',
            children: {
              OKTA: {
                path: 'okta',
              },
              ENTRA: {
                path: 'entra',
              },
              ONELOGIN: {
                path: 'onelogin',
              },
              JUMPCLOUD: {
                path: 'jumpcloud',
              },
              GOOGLE: {
                path: 'google',
              },
            },
          },
          NOTIFICATIONS: {
            path: 'notifications',
            children: {
              SLACK: {
                path: 'slack',
              },
            },
          },
          WORKFLOWS: {
            path: 'workflows',
            children: {
              JIRA: {
                path: 'jira',
              },
              VISO_TRUST: {
                path: 'viso_trust',
              },
            },
          },
          AWS: {
            path: 'aws',
          },
        },
      },
    },
  },
} as const);
