import { useCallback, useEffect, useState } from 'react';
import { FileAccepted, FileRejected } from '@verticeone/design-system';
import { autoDownload } from '@verticeone/utils/file';
import { FileMetaData } from '@vertice/slices';
import {
  useDeleteFileMutation,
  useLazyGetPreSignedLinkQuery,
  useListFilesQuery,
  useUploadFileMutation,
} from '@vertice/slices';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

type FileIdentifier = {
  fullPath: string;
  name: string;
};

type UseFilesProps = {
  pathPrefix: string;
  onChange?: (files: FileIdentifier[]) => void;
};

const getFiles = (files: Array<FileMetaData>) => files.filter(({ type }) => type === 'FILE');

const useFiles = (props: UseFilesProps) => {
  const { pathPrefix, onChange } = props;
  const [files, setFiles] = useState<FileAccepted[]>([]);
  const { accountId } = useAccountContext();

  const [uploadFile] = useUploadFileMutation();
  const [removeFile] = useDeleteFileMutation();
  const [getPreSignedLink] = useLazyGetPreSignedLinkQuery();

  const { data: standardAssetsData, isFetching } = useListFilesQuery(
    {
      accountId: accountId,
      'path+': `${pathPrefix}`,
    },
    { refetchOnMountOrArgChange: true }
  );

  const filePath = useCallback(
    (fileName: string) => {
      return `${pathPrefix}/${fileName}`;
    },
    [pathPrefix]
  );

  useEffect(() => {
    onChange?.(
      files?.map(({ file }) => ({
        fullPath: filePath(file.name),
        name: file.name,
      })) ?? []
    );
  }, [files, filePath, onChange]);

  useEffect(() => {
    const fileList = getFiles(standardAssetsData?.files || []);

    const mappedAttachments = fileList
      .map(
        (attachment) =>
          ({
            name: attachment.fileName,
            size: attachment.fileSize,
            lastModified: attachment.lastModified ? new Date(attachment.lastModified).getTime() : 0,
          } as unknown as File)
      )
      // the files come strangely sorted from the server
      .sort((f1, f2) => f1.name.localeCompare(f2.name));
    setFiles(mappedAttachments.map((file) => ({ file })));
  }, [standardAssetsData]);

  const uploadFiles = async (newFiles: FileAccepted[]) => {
    const originalFiles = files?.map((file) => file.file);
    const allAcceptedFiles = [
      ...(originalFiles ?? []),
      // the "lastModified" property will be set to now on the server so we set it to now here as well
      // otherwise the original date would be shown when the file has been added, but it would change to the date of addition on reload
      ...newFiles.map(({ file }) => new File([file], file.name, { lastModified: new Date().getTime() })),
    ];
    setFiles(
      allAcceptedFiles
        .map((file) => ({
          file,
          status: 'done',
        }))
        .sort((f1, f2) => f1.file.name.localeCompare(f2.file.name))
    );
    if (pathPrefix) {
      const uploadPromises: Promise<string>[] = [];
      newFiles.forEach((newFile) => {
        void uploadFile({
          accountId: accountId,
          'path+': filePath(newFile.file.name),
          body: newFile.file,
        }).unwrap();
      });

      await Promise.all(uploadPromises);
    }
  };

  const deleteFile = async (removedFiles: FileAccepted[]) => {
    const originalFiles = files?.map((file) => file.file);
    const removedFileNames = removedFiles.map((f) => f.file.name);
    const allAcceptedFiles = originalFiles?.filter((f) => !removedFileNames.includes(f.name)) ?? [];
    setFiles(allAcceptedFiles.map((file) => ({ file, status: 'deleted' })));

    if (pathPrefix) {
      const removePromises: Promise<unknown>[] = removedFiles.map((file) => {
        return removeFile({
          accountId: accountId,
          'path+': filePath(file.file.name),
        }).unwrap();
      });

      await Promise.all(removePromises);
    }
  };

  const downloadFile = async (file: FileAccepted | FileRejected) => {
    const fileName = file.file.name;
    if (pathPrefix) {
      void getPreSignedLink({
        accountId: accountId,
        'path+': filePath(fileName),
        responseContentDisposition: 'attachment',
      })
        .unwrap()
        .then((reply) => {
          if (reply.preSignedLink) {
            autoDownload(reply.preSignedLink, fileName);
          }
        });
    }
  };

  return {
    files: files,
    isFetching: isFetching && !files,
    uploadFiles,
    deleteFile,
    downloadFile,
  };
};

export default useFiles;
