import { FC } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider } from 'react-hook-form';

import { GatewayDefinition, FlowEdgeDefinition, ProcessDefinition, Gateway } from '../../../../definitionsTypes';
import {
  EditorDrawer,
  EditorDrawerHeader,
  EditorDrawerFooter,
  EditorDrawerScrollContainer,
  EditorDrawerForm,
} from '../../EditorDrawer';
import { EditGatewayForm } from '../../forms/EditGatewayForm/EditGatewayForm';
import { EditGatewayFormData, editGatewayFormSchema } from '../../forms/EditGatewayForm/schema';
import { isFlowEdgeConditionConfiguration } from '../../../../pocWorkflowSchema';
import { updateEdgeDefinition } from '../../../../definitions/edgeDefinition';

const putDefaultEdgeLast = (edges: FlowEdgeDefinition[]) => {
  const defaultEdge = edges.find(
    ({ edge }) => edge.configurations?.find(isFlowEdgeConditionConfiguration)?.condition.conditionType === 'DefaultFlow'
  );

  if (!defaultEdge) {
    return edges;
  }

  return edges.filter(({ edge }) => edge.id !== defaultEdge.edge.id).concat(defaultEdge);
};

const getEdgesDefaultValues = ({
  edges,
  gatewayType,
}: {
  edges: FlowEdgeDefinition[];
  gatewayType: Gateway['gatewayType'];
}) => {
  const skipDefaultEdgeSort = gatewayType === 'Fork';

  return (skipDefaultEdgeSort ? edges : putDefaultEdgeLast(edges)).map(({ edge }) => {
    const edgeCondition = edge.configurations?.find(isFlowEdgeConditionConfiguration);

    return {
      id: edge.id,
      name: edge.name || '',
      condition: edgeCondition?.condition.expression?.replace(/\s+/g, ' ') || '',
      conditionType: edgeCondition?.condition.conditionType || 'JsonLogic',
    };
  });
};

const getFinalEdgeCondition = (edge: EditGatewayFormData['edges'][number]) => {
  if (edge.condition === '') {
    return {
      conditionType: 'DefaultFlow',
    };
  }

  return {
    conditionType: edge.conditionType,
    expression: edge.condition,
  };
};

type EditGatewayDrawerProps = {
  isOpen: boolean;
  gatewayDefinition?: GatewayDefinition;
  gatewayLeavingEdges: FlowEdgeDefinition[];
  onClose: VoidFunction;
  onSave: (newGatewayDefinition: GatewayDefinition, newEdgesDefinitions: FlowEdgeDefinition[]) => void;
  onDirty: VoidFunction;
  onEdgeSelected: (edgeId: string | undefined) => void;
  selectedEdgeId: string | undefined;
  processDefinition?: ProcessDefinition;
  workflowServiceRef?: string;
};

const GATEWAY_DESCRIPTION_KEY_MAP = {
  Exclusive: 'EDIT_GATEWAY.GATEWAY_TYPE.EXCLUSIVE.DESCRIPTION',
  Fork: 'EDIT_GATEWAY.GATEWAY_TYPE.FORK.DESCRIPTION',
  Join: 'EDIT_GATEWAY.GATEWAY_TYPE.JOIN.DESCRIPTION',
} as const;

const EditGatewayDrawerContent: FC<Omit<Required<EditGatewayDrawerProps>, 'isOpen'>> = ({
  gatewayDefinition,
  onClose,
  onSave,
  onDirty,
  onEdgeSelected,
  selectedEdgeId,
  processDefinition,
  gatewayLeavingEdges,
  workflowServiceRef,
}) => {
  const { gateway } = gatewayDefinition;
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR' });

  const handlers = useForm<EditGatewayFormData>({
    resolver: zodResolver(editGatewayFormSchema),
    defaultValues: {
      gatewayType: gateway.gatewayType,
      edges: getEdgesDefaultValues({ edges: gatewayLeavingEdges, gatewayType: gateway.gatewayType }),
    },
  });

  const onSubmit = (data: EditGatewayFormData) => {
    const updatedGatewayDefinition = {
      ...gatewayDefinition,
      gateway: {
        ...gatewayDefinition.gateway,
        gatewayType: data.gatewayType,
      },
    };
    const updatedEdgeDefinitions: FlowEdgeDefinition[] = [];

    for (const edge of data.edges) {
      const outgoingLeavingEdge = gatewayLeavingEdges.find((leavingEdge) => leavingEdge.edge.id === edge.id);

      if (!outgoingLeavingEdge) {
        continue;
      }

      updatedEdgeDefinitions.push(
        updateEdgeDefinition({
          edgeDefinition: outgoingLeavingEdge,
          edge: {
            name: edge.name,
            configurations: [
              {
                kind: 'ProcessEngine:FlowEdgeCondition',
                condition: getFinalEdgeCondition(edge),
                version: outgoingLeavingEdge.edge.configurations?.find(isFlowEdgeConditionConfiguration)?.version,
              },
            ],
          },
        })
      );
    }

    onSave(updatedGatewayDefinition, updatedEdgeDefinitions);
    onClose();
  };

  return (
    <FormProvider {...handlers}>
      <EditorDrawerForm onSubmit={handlers.handleSubmit(onSubmit)}>
        <EditorDrawerHeader
          title={t('EDIT_GATEWAY.TITLE')}
          subtitle={t(GATEWAY_DESCRIPTION_KEY_MAP[gateway.gatewayType])}
        />
        <Stack direction="column" flexGrow={1} width="100%" minHeight={0}>
          <EditorDrawerScrollContainer>
            <EditGatewayForm
              onEdgeSelected={onEdgeSelected}
              selectedEdgeId={selectedEdgeId}
              processDefinition={processDefinition}
              workflowServiceRef={workflowServiceRef}
              gatewayId={gateway.id}
              gatewayType={gateway.gatewayType}
              onDirty={onDirty}
            />
          </EditorDrawerScrollContainer>
          <EditorDrawerFooter onDiscard={onClose} />
        </Stack>
      </EditorDrawerForm>
    </FormProvider>
  );
};

export const EditGatewayDrawer: FC<EditGatewayDrawerProps> = ({
  isOpen,
  gatewayDefinition,
  onClose,
  onSave,
  onDirty,
  onEdgeSelected,
  selectedEdgeId,
  processDefinition,
  gatewayLeavingEdges,
  workflowServiceRef,
}) => {
  return (
    <EditorDrawer open={isOpen}>
      {gatewayDefinition && processDefinition && workflowServiceRef && (
        <EditGatewayDrawerContent
          workflowServiceRef={workflowServiceRef}
          gatewayLeavingEdges={gatewayLeavingEdges}
          onDirty={onDirty}
          onEdgeSelected={onEdgeSelected}
          selectedEdgeId={selectedEdgeId}
          onSave={onSave}
          onClose={onClose}
          gatewayDefinition={gatewayDefinition}
          key={gatewayDefinition.gateway.id}
          processDefinition={processDefinition}
        />
      )}
    </EditorDrawer>
  );
};
