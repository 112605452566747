import { PropsWithChildren } from 'react';
import { Box } from '@mui/material';

type CardWrapperProps = {
  isVisible?: boolean;
} & PropsWithChildren;

export const CardWrapper = ({ children, isVisible = true }: CardWrapperProps) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Box sx={{ minWidth: '400px' }}>
      {children}
    </Box>
  );
};
