import {
  useOnSelectionChange,
  useReactFlow,
  type OnSelectionChangeFunc,
  useStoreApi,
  Node,
  ReactFlowProvider,
  Edge,
} from '@xyflow/react';
import React, { useCallback, useMemo, useState } from 'react';
import { DRAWER_WIDTH, TRANSITION_DURATION } from '../WorkflowViewer/constants';
import isEqual from 'lodash/isEqual';

type UseWorkflowRendererStateProps = {
  onSelectionChanged: (node?: Node, edge?: Edge) => void;
  checkChangeSelectionAllowed: () => Promise<boolean>;
};

export const useWorkflowRendererState = ({
  onSelectionChanged,
  checkChangeSelectionAllowed,
}: UseWorkflowRendererStateProps) => {
  const flow = useReactFlow();
  const flowApi = useStoreApi();

  const [selectedNodeIds, setSelectedNodeIds] = useState<string[]>([]);
  const [selectedEdgeIds, setSelectedEdgeIds] = useState<string[]>([]);

  const handleSelectionChange: OnSelectionChangeFunc = useCallback(
    async ({ nodes: changedNodes, edges: changedEdges }) => {
      if (changedNodes.length === 0 && changedEdges.length === 0) {
        return;
      }
      const changedNodesIds = changedNodes.map((n) => n.id);
      const changedEdgeIds = changedEdges.map((e) => e.id);

      if (isEqual(changedNodesIds, selectedNodeIds) && isEqual(changedEdgeIds, selectedEdgeIds)) {
        return;
      }

      const changeAllowed = await checkChangeSelectionAllowed();

      // if change is not allowed then reset to last selection
      if (!changeAllowed) {
        flowApi.setState((s) => {
          s.resetSelectedElements();
          s.addSelectedNodes(selectedNodeIds);
          s.addSelectedEdges(selectedEdgeIds);
          return s;
        });
        return;
      }

      setSelectedNodeIds(changedNodesIds);
      setSelectedEdgeIds(changedEdgeIds);

      const node = changedNodes[0];
      const edge = changedEdges[0];

      onSelectionChanged(node, edge);

      const edgeSourceNode = edge ? flowApi.getState().nodes.find((n) => n.id === edge.source) : undefined;
      const nodeToZoom = edgeSourceNode || node;
      if (nodeToZoom) {
        const targetZoom = Math.max(flow.getZoom(), 0.7);
        const x = nodeToZoom.position.x + (nodeToZoom?.measured?.width ?? 0) / 2 + DRAWER_WIDTH / 2 / targetZoom;
        const y = nodeToZoom.position.y + (nodeToZoom?.measured?.height ?? 0) / 2;
        void flow.setCenter(x, y, { zoom: targetZoom, duration: TRANSITION_DURATION });
      }
    },
    [checkChangeSelectionAllowed, flow, flowApi, onSelectionChanged, selectedEdgeIds, selectedNodeIds]
  );

  const clearRendererSelection = useCallback(() => {
    flow.setNodes((n) => n.map((node) => ({ ...node, selected: false })));
    flow.setEdges((e) => e.map((edge) => ({ ...edge, selected: false })));

    setSelectedNodeIds([]);
    setSelectedEdgeIds([]);
  }, [flow]);

  useOnSelectionChange({
    onChange: handleSelectionChange,
  });

  return useMemo(
    () => ({
      clearRendererSelection,
    }),
    [clearRendererSelection]
  );
};

// A component using useWorkflowRendererState must be wrapped in ReactFlowProvider which this HOC does
export const withWorkflowRenderer =
  <T extends object>(InnerComponent: React.FC<T>) =>
  (props: T) =>
    (
      <ReactFlowProvider>
        <InnerComponent {...props} />
      </ReactFlowProvider>
    );
